<template>
  <div id="container">
    <div class="logo">
      <model-viewer src="ava2.glb" shadow-intensity="1" exposure="2" environment-image="four.hdr" autoplay></model-viewer>
    </div>
    <div class="test-box">
      <p class="test">Test 1</p>
      <p class="test-name">HOLDYOURLOVE</p>
      <p class="status">Completed</p>
    </div>
    <div class="help-txt" v-show="false">
      <p>Use your fingers or mouse to examine models</p>
    </div>
    <header>
      <button class="external">
        <a href="https://www.youtube.com/watch?v=S-cUXvsZAKA">
          Watch
        </a>
      </button>
      <button class="external">Listen</button>
    </header>
    <footer>
      <section>
        <h3>AVA ENGINEERING</h3>
        <p class="big-text">Revisiting the basis of human emotion and interaction in order to ensure equality in a
          multi-intelligent society.</p>
        <p class="email">
          <a href="mailto:ideas@aericvala.com">
            ideas@aericvala.com
          </a>
        </p>
        <p class="copyright">© AERICVALA 2023</p>
      </section>
      <section>
      </section>
    </footer>
    <p class="info">
      <a href="https://www.instagram.com/aericvala/">@</a>
    </p>
    <div class="loading" v-show="loading">
      <p class="loading-text">Loading 3d models<span class="blink">...</span></p>
      <p class="fade-in">AVA</p>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';


export default {
  name: 'App',
  data() {
    return {
      loading: true
    }
  },
  methods: {
    loadModel() {
      let self = this
      const container = document.getElementById('container');

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);

      const pmremGenerator = new THREE.PMREMGenerator(renderer);

      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0, 0, 0);
      scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 0.04).texture;

      const light = new THREE.PointLight(0xff8fab, 2, 25)
      light.position.x = 5
      light.position.y = -3
      light.position.z = 15
      scene.add(light)

      const camera = new THREE.PerspectiveCamera(3, window.innerWidth / window.innerHeight, 1, 100);
      camera.position.set(-72, 20, 0);

      

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0.5, 0);
      controls.update();
      controls.enablePan = false;
      controls.enableDamping = true;

      const mloader = new MTLLoader();
      mloader.load('/b10.mtl', function (materials) {

        materials.preload();

        const loader = new OBJLoader();
        loader.setMaterials(materials);
        loader.load('/b10.obj', function (object) {

          scene.add(object);
          self.loading = false
        });

      });

      window.onresize = function () {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

      };

      function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera, light);
      }

      animate()
    }
  },
  mounted() {
    //setTimeout(() => this.loading = false, 10000)
    this.loadModel()
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap');

body {
  margin: 0;
  background-color: black;
  padding: 0;
  overflow: hidden;
}

#container {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

a {
  color: #fff;
  text-decoration: none;
}

.logo {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo p {
  margin: 0;
  margin-top: -3em;
  font-family: 'Helvetica', sans-serif;
  font-size: .6em;
  padding: .5em 1em;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .1);
}

.help-txt {
  position: fixed;
  top: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-txt p {
  font-size: .6em;
  font-family: 'Helvetica', sans-serif;
  padding: .5em 1em;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 10px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header button {
  padding: .5em 1em;
  font-family: 'Helvetica', sans-serif;
  font-size: .7em;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.lyric {
  margin-bottom: 1em;
  font-family: 'Helvetica', sans-serif;
  font-size: .6em;
  line-height: 1.3;
}

.lyric p {
  margin: 0;
}

.test-box {
  margin-bottom: 1em;
  font-family: 'Helvetica', sans-serif;
  cursor: pointer;
  position: fixed;
  top: 7em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.test-box p {
  margin: 0;
  line-height: 1.5;
}

.test {
  opacity: .5;
  font-size: .6em;
}

.test-name {
  font-size: .6em;
}

.status {
  font-size: .6em;
  opacity: .8;
}

.tests {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tests p {
  font-family: 'Helvetica', sans-serif;
  font-size: .6em;
  padding: .5em 1em;
  border-radius: 50%;
  border: 1px solid white;
}

.soon {
  opacity: .6;
}

.later {
  opacity: .4;
}

.latest {
  opacity: .2;
}

footer {
  position: fixed;
  bottom: 1%;
  left: 0;
  font-family: 'Helvetica', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  box-sizing: border-box;
}

footer h3 {
  background-color: white;
  color: black;
  padding: .5em 1em;
  margin-bottom: .5em;
  display: inline-block;
  font-size: 1em;
}

footer .big-text {
  margin-top: 0;
  font-size: .7em;
  width: 180px;
  line-height: 1.5;
  text-align: justify;
}

footer .email {
  font-size: .7em;
  text-decoration: underline;
}

footer .copyright {
  font-size: .6em;
}

.info {
  font-family: monospace;
  font-size: 1.25em;
  padding: .5em 1em;
  background-color: rgba(255, 255, 255, .1);
  position: fixed;
  bottom: 2%;
  right: 0;
  margin: 0 1em;
  box-sizing: border-box;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
}

.loading p {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  font-size: 40vw;
}

.loading .loading-text {
  font-family: monospace;
  font-size: .8em;
  position: absolute;
  top: 2%;
  left: 1%;
}

#typing {
  position: absolute;
  top: 2em;
  left: 2em;
  font-family: monospace;
  font-size: .9em;
  color: rgb(21, 153, 7);
}

.blink {
  animation: blink-animation .6s steps(5, start) infinite;
  -webkit-animation: blink-animation .6s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}

@-webkit-keyframes blink-animation {
  to {
    opacity: 0;
  }
}

.fade-in {
  animation-duration: 10s;
  animation-name: fade-in;
  animation-timing-function: linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .loading .loading-text {
    left: 3%;
  }
}

@media screen and (max-width: 500px) {
  .loading .loading-text {
    left: 5%;
  }
}
</style>
